@mixin ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc($height / $width) * 100%;
  }
}
.ratio-16-9 {
  @include ratio(16, 9);
}
.ratio-9-16 {
  @include ratio(9, 16);
}
.ratio-4-3 {
  @include ratio(4, 3);
}
.ratio-3-4 {
  @include ratio(3, 4);
}

@mixin line-clamp($numLines: 1, $lineHeight: 1.412) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote('em');
}
@mixin line-clamp2($numLines: 2, $lineHeight: 1.412) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote('em');
}
@mixin line-clamp3($numLines: 3, $lineHeight: 1.412) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote('em');
}
.line-clamp-3 {
  @include line-clamp3;
}
.cdk-overlay-container {
  // background-color:rgba(0, 0, 0, 0.5);
  .file-preview-dialog-panel {
    .ratio-16-9 {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
