.animated-arrow {
  width: 10px;
  height: 10px;
  position: relative;
  display: inline-block;
  transition: all 0.3s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    width: 100%;
    height: 1px;
    transition: all 0.3s;
  }
  &:before {
    transform: rotate(45deg);
    left: -1px;
  }
  &:after {
    transform: rotate(-45deg);
    left: 6px;
  }
  &.arrow-white {
    &:before,
    &:after {
      background-color: $white;
    }
  }
  &.arrow-primary {
    &:before,
    &:after {
      background-color: $black;
    }
  }
  &.arrow-text {
    &:before,
    &:after {
      background-color: $text;
    }
  }
  &.arrow-up {
    &:before {
      left: -1px;
      transform: rotate(-45deg);
      width: 10px;
    }
    &:after {
      transform: rotate(45deg);
      width: 10px;
      right: -3px;
    }
  }
  &.arrow-down {
    &:before {
      left: -1px;
      transform: rotate(45deg);
      width: 10px;
    }
    &:after {
      width: 10px;
      transform: rotate(-45deg);
      right: -3px;
    }
  }
  &.right {
    &:before,
    &:after {
      left: 1px;
      transition: all 0.3s;
    }
    &:before {
      transform: rotate(45deg);
      top: -1px;
    }
    &:after {
      transform: rotate(-45deg);
      top: 5px;
    }
  }
  &.left {
    &:before,
    &:after {
      left: 1px;
    }
    &:before {
      transform: rotate(-45deg);
      top: -1px;
    }
    &:after {
      transform: rotate(45deg);
      top: 5px;
    }
  }
}
.dark {
  .animated-arrow {
    &.arrow-text {
      &:before,
      &:after {
        background-color: $white !important;
      }
    }
    &.arrow-primary {
      &:before,
      &:after {
        background-color: $white !important;
      }
    }
  }
}
