.expansion-panel {
  width: 100%;
  border: solid thin $border;
  border-radius: 8px;
  margin-bottom: 16px;

  .expansion-panel-header {
    border-bottom: solid thin $border;
    position: relative;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 16px;
    .animated-arrow {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 16px;
      height: 16px;
    }
    &.border-hidden {
      border-bottom: none;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background-color: lighten($body, 3%);
      }
    }
  }
  .expansion-panel-body {
    padding: 8px 16px;
    p {
      font-size: 14px;
      font-weight: 500;
    }
    label {
      font-size: 10px;
    }
  }
}
.dark {
  .expansion-panel-header {
    color: $white;
  }
  .expansion-panel-body {
    p {
      color: $white;
    }
  }
}
