.answer-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .option-box {
    background-color: $body;
    border-radius: 8px;
    margin-bottom: 16px;
    padding-top: 13px;
    padding-bottom: 13px;
    label {
      padding-right: 12px;
      white-space: break-spaces;
      word-break: break-word;
    }

    &.full {
      width: 100%;

      &.media {
        max-height: 150px;
        padding: 0;
        display: flex;
        overflow: hidden;

        @media (max-width: 640px) {
          display: block;
          height: auto;
          max-height: inherit;
        }

        .content {
          padding-top: 16px;
          flex-grow: 1;

          div {
            &:first-child {
              padding-right: 16px;
            }
          }

          .description {
            padding-top: 16px;
            overflow-y: auto;
            height: 90px;
          }

          @media (max-width: 540px) {
            .description {
              padding: 16px;
            }
          }
        }

        .ratio-16-9 {
          &.media {
            background-color: darken($body, 10%);
            app-icon {
              width: 32px;
              height: 32px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              opacity: 0.5;
              cursor: pointer;
            }
            > img {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
              cursor: pointer;
              transition: all 0.3s;
            }
          }
          max-width: 250px;
          min-width: 250px;
          width: 250px;

          @media (max-width: 640px) {
            max-width: inherit;
            min-width: inherit;
            width: inherit;
          }

          iframe {
            position: absolute;
            top: 0;
            width: 100%;
          }

          &:hover {
            img {
              opacity: 0.8;
            }
          }
        }
      }
    }

    &.option-box-small {
      width: calc(50% - 12px);
      padding-bottom: 0;
      overflow: hidden;

      @media (max-width: 540px) {
        width: 100%;
      }

      .header {
        display: flex;
        padding-bottom: 13px;
      }

      .image-container {
        position: relative;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.3s;
        }

        &:hover {
          img {
            opacity: 0.8;
          }
        }
      }

      .video-wrapper {
        width: 100%;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}
.dark {
  .option-box {
    background-color: $body-dark;
    .ratio-16-9.media {
      background-color: darken($body-dark, 5%) !important;
    }
  }
}
