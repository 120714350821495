.info-box {
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  background-color: rgba($blue, 0.1);
  color: $blue;
  > app-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: $blue;
    margin-right: 16px;
  }
  p,
  div {
    flex-grow: 1;
    app-icon {
      width: 16px;
      height: 16px;
      fill: $blue;
    }
  }
  &.error {
    background-color: rgba($error, 0.1);
    color: $error;
    > app-icon {
      width: 24px;
      height: 24px;
      fill: $error;
      margin-right: 16px;
    }
    p {
      app-icon {
        width: 16px;
        height: 16px;
        fill: $error;
      }
    }
    a {
      color: darken($error, 10%) !important;
      &:hover {
        color: darken($error, 15%) !important;
      }
    }
    .btn-primary {
      background-color: $error;
      &:hover {
        background-color: lighten($error, 10%);
      }
      &:active {
        background-color: darken($error, 15%);
      }
    }
    .btn-secondary {
      color: $error;
      &:hover {
        border: solid thin $error;
      }
      &:active {
        border-color: darken($error, 15%);
        color: darken($error, 15%);
      }
    }
  }
  &.success {
    background-color: rgba($success, 0.1);
    color: $success;

    > app-icon {
      width: 24px;
      height: 24px;
      fill: $success;
      margin-right: 16px;
    }

    p {
      app-icon {
        width: 16px;
        height: 16px;
        fill: $success;
      }
    }

    a {
      color: darken($success, 10%) !important;

      &:hover {
        color: darken($success, 15%) !important;
      }
    }
    .btn-primary {
      background-color: $success;
      &:hover {
        background-color: lighten($success, 10%);
      }
      &:active {
        background-color: darken($success, 15%);
      }
    }
    .btn-secondary {
      color: $error;
      &:hover {
        border: solid thin $success;
      }
      &:active {
        border-color: darken($success, 15%);
        color: darken($success, 15%);
      }
    }
  }
  &.warning {
    background-color: rgba($orange, 0.1);
    color: $orange;
    > app-icon {
      width: 24px;
      height: 24px;
      fill: $orange;
      margin-right: 16px;
    }
    p {
      app-icon {
        width: 16px;
        height: 16px;
        fill: $orange;
      }
    }
    a {
      color: darken($orange, 10%) !important;
      &:hover {
        color: darken($orange, 15%) !important;
      }
    }
    .btn-primary {
      background-color: $orange;
      &:hover {
        background-color: lighten($orange, 10%);
      }
      &:active {
        background-color: darken($orange, 15%);
      }
    }
    .btn-secondary {
      color: $orange;
      &:hover {
        border: solid thin $orange;
      }
      &:active {
        border-color: darken($orange, 15%);
        color: darken($orange, 15%);
      }
    }
  }
  &.inverted {
    background-color: rgba($primary-dark, 0.2) !important;
    color: $primary-dark !important;
    > app-icon {
      fill: $primary-dark;
    }
  }
}
.dark {
  .info-box {
    background-color: rgba($primary-dark, 0.2) !important;
    color: $primary-dark !important;
    > app-icon {
      fill: $primary-dark;
    }
    &.warning {
      background-color: rgba($orange, 0.1) !important;
      color: $orange !important;
      > app-icon {
        fill: $orange !important;
      }
    }
    &.error {
      background-color: rgba($error, 0.1) !important;
      color: $error !important;
      > app-icon {
        fill: $error !important;
      }
      p {
        app-icon {
          fill: $error !important;
        }
      }
      a {
        color: darken($error, 10%) !important;
        &:hover {
          color: darken($error, 15%) !important;
        }
      }
    }
  }
}
