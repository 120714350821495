.days-progress_circle {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 34px;
    z-index: 10;
    color: $white;

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    span {
        width: 34px;
        height: 34px;
        text-align: center;
        display: block;
        line-height: 34px;
        font-size: 14px;
        font-weight: 700;
        color: $white;
    }

    .progress-ring__circle {
        transition: 0.35s stroke-dashoffset;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke: $white;
    }

    .progress-ring__circle_out {
        transition: 0.35s stroke-dashoffset;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke: rgba($white, 0.3);
    }
}
