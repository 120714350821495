.ck-content {
  padding: 0 16px;
}
.form-group {
  padding-bottom: 24px;
  position: relative;
  width: 100%;
  label {
    font-size: 14px;
    color: darken($border, 25%);
    margin-bottom: 4px;
    min-height: 13px;
    display: inline-block;
    width: 100%;
  }
  > input,
  > textarea {
    background-color: $input;
    border: solid thin $border;
    border-radius: 8px;
    width: 100%;
    outline: 0 !important;
    &:focus {
      background-color: $white;
    }
    &:disabled {
      background-color: rgba($input, 0.3);
      border-color: rgba($border, 0.3);
      cursor: not-allowed;
    }
  }
  input {
    height: 40px;
    padding: 0 16px;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px $input;
  }
  textarea {
    padding: 16px;
  }
  .error-msg {
    font-size: 12px;
    position: absolute;
    left: 0px;
    color: $error;
    white-space: nowrap;
    &.top {
      bottom: inherit;
      left: inherit;
      right: 0;
      top: 4px;
    }
  }
  &.select {
    .error-msg {
      bottom: 4px;
    }
  }
  .text-count {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 11px;
  }
  .ng-touched {
    &.ng-invalid {
      border-color: $error !important;
      ::placeholder {
        color: $error !important;
      }
      .ck.ck-editor__editable_inline {
        border-color: $error !important;
        ::placeholder {
          color: $error !important;
        }
      }
    }
  }
  .error-block {
    border-radius: 8px;
    border: solid thin $error;
    padding: 8px;
    margin-top: 8px;
    transition: all 0.3s;
    background-color: rgba($error, 0.1);
    p {
      font-size: 14px;
      color: $error;
      padding-bottom: 8px;
    }
  }

  .cke_textarea_inline {
    min-height: 200px;
    border: solid thin $border;
    border-radius: 8px;
    padding: 8px 16px;
    &:focus {
      outline: 0;
      border: solid thin $blue;
    }
  }
  &.number {
    display: flex;
    height: 64px;
    position: relative;
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 64px;
      font-size: 24px;
      padding-right: 20px;
      padding-left: 50px;
      &:focus {
        border: solid thin $blue;
        outline: none !important;
        box-shadow: 0 0 0 !important;
      }
    }
    span {
      display: flex;
      flex-direction: column;
      width: 40px;
      border: solid thin $border;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
      margin-left: -2px;
      height: 64px;

      button {
        flex: 1 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $input;
        transition: all 0.3s;
        app-icon {
          width: 20px;
          height: 20px;
          fill: $text;
          transform: rotate(90deg);
          opacity: 0.6;
          transition: all 0.3s;
        }
        &:first-child {
          border-bottom: solid thin $border;
        }
        &:hover {
          app-icon {
            opacity: 1;
          }
        }
        &:active {
          background-color: $white;
        }
      }
    }
    > app-icon {
      position: absolute;
      left: 12px;
      width: 32px;
      height: 32px;
      top: 16px;
    }
    &.focus {
      span {
        border: solid thin $blue;
      }
    }
    &.error {
      span {
        border: solid thin $error;
      }
      input {
        border: solid thin $error;
      }
    }
  }
  &.xl {
    input {
      height: 56px;
      font-size: 20px;
    }
  }
  &.sm {
    height: 40px;
    input {
      height: 40px;
      font-size: 16px;
      padding: 0 16px 0 44px;
    }
    span {
      height: 40px;

      app-icon {
        height: 16px !important;
        width: 16px !important;
      }
    }
    > app-icon {
      position: absolute;
      top: 8px;
      left: 12px;
      width: 24px;
      height: 24px;
    }
  }
  &.addon-f {
    app-icon {
      position: absolute;
      left: 16px;
      top: 38px;
      opacity: 0.5;
      width: 16px;
      height: 16px;
    }
    input {
      padding-left: 48px;
    }
  }
  &.addon-e {
    app-icon {
      position: absolute;
      right: 16px;
      top: 38px;
      width: 16px;
      height: 16px;
    }
    input {
      padding-right: 48px;
    }
  }
  &.disabled {
    span {
      border-color: rgba($border, 0.3);
      button {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}
app-proposal-teaser,
app-review-teaser,
app-review-create,
app-swag-teaser,
app-creative-query-teaser-view {
  .form-group {
    input,
    textarea {
      border: solid thin $border-dark;
      background-color: darken(rgba($sidebar, 0.7), 10%);
      color: $white;
      &:focus {
        background-color: darken(rgba($sidebar, 0.7), 15%);
      }
    }
    &.number {
      span {
        border: solid thin $border-dark;

        button {
          background: darken(rgba($sidebar, 0.7), 10%);
          color: $white;
          transition: all 0.3s;

          app-icon {
            fill: $white;
          }

          &:first-child {
            border-bottom: solid thin $border-dark;
          }

          &:hover {
            app-icon {
              opacity: 1;
            }
          }

          &:active {
            background: darken(rgba($sidebar, 0.7), 1%);
          }
          &:disabled {
            opacity: 0.2;
            cursor: not-allowed;
          }
        }
      }
      &.disabled {
        span {
          button:disabled {
            opacity: 0.2;
            cursor: not-allowed;
          }
        }
      }
      app-icon {
        fill: $white;
      }
    }
  }
}

.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 20px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 4px;
  }
  &:hover input ~ .checkmark {
    background-color: $white;
  }
  input:checked ~ .checkmark {
    background-color: $blue;
    border-color: $blue;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}

.radio {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $text !important;

  &.text-white {
    color: $white !important;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid $border;
    border-radius: 50%;
    z-index: 0;
    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $blue;
    }
  }
  &:hover input ~ .checkmark {
    border-color: $blue;
  }
  input:checked ~ .checkmark {
    border-color: $blue;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  input.checked ~ .checkmark:after {
    display: block;
  }
  input:disabled {
    cursor: not-allowed;
  }
  &:hover {
    input:disabled ~ .checkmark {
      border-color: $border !important;
    }
  }
  &.disabled {
    color: $border !important;
  }
  &.inverted {
    .checkmark {
      background-color: transparent;
      border: 2px solid rgba($white, 0.6);
    }
    &:hover input ~ .checkmark {
      border: 2px solid rgba($white, 1);
      background-color: rgba($white, 0.1);
    }

    input:checked ~ .checkmark {
      border-color: $white;
    }
    .checkmark:after {
      background: $white;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
  height: 14px;
  border-radius: 28px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 28px;
    &:before {
      position: absolute;
      content: '';
      height: 10px;
      width: 10px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 24px;
    }
  }
  input:checked + .slider {
    background-color: $blue;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $blue;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }
  input:disabled + .slider {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

input.rounded {
  background-color: #f8f8f8;
  border: solid thin #d6dbe0;
  border-radius: 8px;
  outline: 0 !important;
  &:focus {
    background-color: $white;
  }
  &:disabled {
    background-color: rgba($input, 0.3);
    border-color: rgba($border, 0.3);
    cursor: not-allowed;
  }
}

/* Rounded sliders */
.slider.rounded {
  border-radius: 34px;
}

.slider.rounded:before {
  border-radius: 50%;
}
app-media-block,
app-add-video {
  .form-group {
    padding-bottom: 8px;
  }
}

ckeditor {
  ol,
  ul {
    margin-left: 16px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: outside;
  }
}

.dark {
  .ck-placeholder {
    color: rgba($white, 0.3) !important;
    font-size: 16px !important;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 200 !important;
  }
  .form-group {
    label {
      color: $white;
    }
    input,
    textarea {
      border: solid thin $border-dark !important;
      background-color: darken(rgba($sidebar-dark, 0.7), 10%) !important;
      color: $white !important;
      &:focus {
        background-color: darken(rgba($sidebar-dark, 0.7), 15%);
      }
      &::placeholder {
        color: rgba($white, 0.3) !important;
        font-size: 16px !important;
        font-family: 'Nunito', sans-serif !important;
        font-weight: 200 !important;
      }
    }
    input:-webkit-autofill {
      background-color: darken(rgba($sidebar-dark, 0.7), 10%);
      color: $white;
    }
    &.number {
      span {
        border: solid thin $border-dark;

        button {
          background: darken(rgba($sidebar-dark, 0.7), 10%);
          color: $white;
          transition: all 0.3s;

          app-icon {
            fill: $white;
          }

          &:first-child {
            border-bottom: solid thin $border-dark;
          }

          &:hover {
            app-icon {
              opacity: 1;
            }
          }

          &:active {
            background: darken(rgba($sidebar-dark, 0.7), 1%);
          }
        }
      }

      app-icon {
        fill: $white;
      }
    }
    .cke_textarea_inline {
      min-height: 200px;
      border: solid thin $border-dark;
      border-radius: 8px;
      padding: 8px 16px;

      &:focus {
        outline: 0;
        border: solid thin $primary-dark;
      }
    }
    > input,
    > textarea {
      &:disabled {
        background-color: transparent !important;
        border-color: rgba($border, 0.1) !important;
        cursor: not-allowed;
      }
    }
  }
  .switch {
    .slider {
      background-color: $border-dark;
      &:before {
        background-color: $sidebar-dark;
        border-radius: 12px;
      }
    }

    input:checked + .slider {
      background-color: $primary-dark;
    }
  }

  .radio {
    color: $white !important;
    .checkmark {
      background-color: transparet;
      border: 2px solid rgba($white, 0.6);
    }

    &:hover input ~ .checkmark {
      border: 2px solid rgba($white, 1);
      background-color: rgba($white, 0.1);
    }

    input:checked ~ .checkmark {
      border-color: $white;
    }

    .checkmark:after {
      background: $white;
    }
    &.disabled {
      color: $border-dark !important;
    }
  }
}
.addon-end {
  input {
    padding-right: 48px;
    text-overflow: ellipsis;
  }
}
