// // [tooltip] {
// //     position: relative;
// //     &:after {
// //         opacity: 0;
// //         visibility: hidden;
// //         content: attr(tooltip);
// //         background-color: $$black;
// //         border-radius: 4px;
// //         font-size: 12px;
// //         padding: 4px 4px;
// //         position: absolute;
// //         color: white;
// //         left: 50%;
// //         top: calc(100% + 8px);
// //         z-index: 100;
// //         transform: translate(-50%, 15px);
// //         transition: all 0.3s;
// //         min-width: 100px;
// //         text-align: center;
// //         backface-visibility: hidden;
// //     }
// //     &:before {
// //         content: '';
// //         width: 0;
// //         height: 0;
// //         border-left: 5px solid transparent;
// //         border-right: 5px solid transparent;
// //         border-bottom: 5px solid $black;
// //         position: absolute;
// //         top: calc(100% - -3px);
// //         left: 50%;
// //         opacity: 0;
// //         visibility: hidden;
// //         transform: translate(-50%, 15px);
// //         transition: all 0.3s;
// //         backface-visibility: hidden;
// //     }
// //     &:hover {
// //         &:after {
// //             opacity: 1;
// //             visibility: visible;
// //             transform: translate(-50%, 0);
// //         }
// //         &:before {
// //             opacity: 1;
// //             visibility: visible;
// //             transform: translate(-50%, 0);
// //         }
// //     }
// // }
// // .tooltip-right-bottom {
// //     &:after {
// //         left: inherit;
// //         right: 0;
// //         transform: translate(0, 15px);
// //     }
// // }
// // [tooltip] {
// //     &.tooltip-right-bottom {
// //         &:hover {
// //             &:after {
// //                 transform: translate(0, 0) !important;
// //             }
// //         }
// //     }
// // }
// // .tooltip-left-bottom {
// //     &:after {
// //         left: 0;
// //         transform: translate(0, 15px);
// //     }
// // }
// // [tooltip] {
// //     &.tooltip-left-bottom {
// //         &:hover {
// //             &:after {
// //                 transform: translate(0, 0) !important;
// //             }
// //         }
// //     }
// // }

// [tooltip] {
//     position: relative;
//     &:before,
//     &:after {
//         opacity: 0;
//         position: absolute;
//         z-index: 10001;
//         left: 50%;
//         backface-visibility: hidden;
//     }
//     &:before {
//         border-style: solid;
//         border-top-width: 4px;
//         border-right-width: 4px;
//         border-bottom-width: 4px;
//         border-left-width: 4px;
//         border-bottom-width: 0;
//         content: '';
//         top: -2px;
//         width: 0;
//         height: 0;
//         transform: translate(-50%, calc(-50% - 6px));
//         transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//             transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//             -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0s;
//         z-index: 110000;
//     }
//     &:after {
//         content: attr(tooltip);
//         text-align: center;
//         padding: 10px 10px 9px;
//         font-size: 16px;
//         border-radius: 8px;
//         color: $tooltip-base-text-color;
//         transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1),
//             transform 0.3s cubic-bezier(0.73, 0.01, 0, 1),
//             -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1);
//         pointer-events: none;
//         z-index: 999;
//         white-space: nowrap;
//         bottom: 100%;
//         transform: translate(-50%, 12px);
//         max-width: 320px;
//         text-overflow: ellipsis;
//         overflow: hidden;
//     }
// }

// [tooltip]:after {
//     background: $tooltip-base-bg-color;
// }

// [tooltip]:before {
//     border-top-color: $tooltip-base-bg-color;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='right']:before {
//     border-top-color: transparent;
//     border-right-color: $tooltip-base-border-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='bottom']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tooltip-base-border-color;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='left']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tooltip-base-border-color;
// }

// [tooltip] {
//     &:focus,
//     &:hover {
//         &:before,
//         &:after {
//             opacity: 1;
//         }
//     }
//     &:focus,
//     &:hover {
//         &:before {
//             transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0.1s,
//                 transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.1s,
//                 -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.1s;
//             transform: translate(-50%, calc(-50% - 2px));
//         }
//         &:after {
//             transform: translate(-50%, -6px);
//         }
//     }
// }

// [tooltip][tooltip-conf*='right']:before {
//     border-style: solid;
//     border-top-width: 4px;
//     border-right-width: 4px;
//     border-bottom-width: 4px;
//     border-left-width: 4px;
//     border-left-width: 0;
//     left: auto;
//     right: -6px;
//     top: 50%;
//     transform: translate(calc(-50% + 7px), -50%);
// }

// [tooltip][tooltip-conf*='right']:after {
//     top: 50%;
//     left: 100%;
//     bottom: auto;
//     -webkit-transform: translate(-12px, -50%);
//     transform: translate(-12px, -50%);
// }

// [tooltip][tooltip-conf*='right']:focus:before,
// [tooltip][tooltip-conf*='right']:hover:before {
//     -webkit-transform: translate(calc(-50% + 3px), -50%);
//     transform: translate(calc(-50% + 3px), -50%);
// }

// [tooltip][tooltip-conf*='right']:focus:after,
// [tooltip][tooltip-conf*='right']:hover:after {
//     -webkit-transform: translate(7px, -50%);
//     transform: translate(7px, -50%);
// }

// [tooltip][tooltip-conf*='bottom']:before {
//     border-style: solid;
//     border-top-width: 4px;
//     border-right-width: 4px;
//     border-bottom-width: 4px;
//     border-left-width: 4px;
//     border-top-width: 0;
//     top: auto;
//     bottom: -6px;
//     left: 50%;
//     -webkit-transform: translate(-50%, calc(-50% + 6px));
//     transform: translate(-50%, calc(-50% + 6px));
// }

// [tooltip][tooltip-conf*='bottom']:after {
//     top: 100%;
//     left: 50%;
//     bottom: auto;
//     -webkit-transform: translate(-50%, -12px);
//     transform: translate(-50%, -12px);
// }

// [tooltip][tooltip-conf*='bottom']:focus:before,
// [tooltip][tooltip-conf*='bottom']:hover:before {
//     -webkit-transform: translate(-50%, calc(-50% + 2px));
//     transform: translate(-50%, calc(-50% + 2px));
// }

// [tooltip][tooltip-conf*='bottom']:focus:after,
// [tooltip][tooltip-conf*='bottom']:hover:after {
//     -webkit-transform: translate(-50%, 6px);
//     transform: translate(-50%, 6px);
// }

// [tooltip][tooltip-conf*='left']:before {
//     border-style: solid;
//     border-top-width: 4px;
//     border-right-width: 4px;
//     border-bottom-width: 4px;
//     border-left-width: 4px;
//     border-right-width: 0;
//     left: -2px;
//     top: 50%;
//     width: 0;
//     height: 0;
//     -webkit-transform: translate(calc(-50% - 8px), -50%);
//     transform: translate(calc(-50% - 8px), -50%);
// }

// [tooltip][tooltip-conf*='left']:after {
//     top: 50%;
//     right: 100%;
//     bottom: auto;
//     left: auto;
//     -webkit-transform: translate(12px, -50%);
//     transform: translate(12px, -50%);
// }

// [tooltip][tooltip-conf*='left']:focus:after,
// [tooltip][tooltip-conf*='left']:hover:after {
//     -webkit-transform: translate(-7px, -50%);
//     transform: translate(-7px, -50%);
// }

// [tooltip][tooltip-conf*='left']:focus:before,
// [tooltip][tooltip-conf*='left']:hover:before {
//     -webkit-transform: translate(calc(-50% - 3px), -50%);
//     transform: translate(calc(-50% - 3px), -50%);
// }

// [tooltip][tooltip-conf*='multiline']:after {
//     word-break: break-word;
//     white-space: normal;
//     min-width: 180px;
//     text-overflow: clip;
// }

// [tooltip][tooltip-conf*='delay']:before {
//     -webkit-transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
//     transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
//     transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
//     transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
// }

// [tooltip][tooltip-conf*='delay']:after {
//     -webkit-transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
//     transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
//     transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
//     transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
// }

// [tooltip][tooltip-conf*='delay']:focus:before,
// [tooltip][tooltip-conf*='delay']:hover:before {
//     -webkit-transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s,
//         -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
//     transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s,
//         -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
//     transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s,
//         transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
//     transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s,
//         transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s,
//         -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
// }

// [tooltip][tooltip-conf*='delay']:focus:after,
// [tooltip][tooltip-conf*='delay']:hover:after {
//     -webkit-transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
//     transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
//     transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s,
//         transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
//     transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s,
//         transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s,
//         -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
// }

// [tooltip][tooltip-conf*='shadow']:after {
//     box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
// }

// [tooltip][tooltip-conf*='no-fading']:after,
// [tooltip][tooltip-conf*='no-fading']:before {
//     -webkit-transition: none;
//     transition: none;
// }

// [tooltip][tooltip-conf*='no-arrow']:before {
//     display: none;
// }

// [tooltip][tooltip-conf*='square']:after {
//     border-radius: 0;
// }

// [tooltip][tooltip-conf*='invert']:after {
//     color: $tooltip-base-text-color;
//     background: $tooltip-invert-bg-color;
// }

// [tooltip][tooltip-conf*='invert']:before {
//     border-top-color: $tooltip-invert-bg-color;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='invert'][tooltip-conf*='right']:before {
//     border-top-color: transparent;
//     border-right-color: $tooltip-invert-bg-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='invert'][tooltip-conf*='bottom']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tooltip-invert-bg-color;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='invert'][tooltip-conf*='left']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tooltip-invert-bg-color;
// }

// [tooltip][tooltip-conf*='success']:after {
//     background: $tooltip-success-bg-color;
// }

// [tooltip][tooltip-conf*='success']:before {
//     border-top-color: $tooltip-success-bg-color;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='success'][tooltip-conf*='right']:before {
//     border-top-color: transparent;
//     border-right-color: $tooltip-success-bg-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='success'][tooltip-conf*='bottom']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tooltip-success-bg-color;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='success'][tooltip-conf*='left']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tooltip-success-bg-color;
// }

// [tooltip][tooltip-conf*='info']:after {
//     background: $tooltip-info-bg-color;
// }

// [tooltip][tooltip-conf*='info']:before {
//     border-top-color: $tooltip-info-bg-color;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='info'][tooltip-conf*='right']:before {
//     border-top-color: transparent;
//     border-right-color: $tooltip-info-bg-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='info'][tooltip-conf*='bottom']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tooltip-info-bg-color;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='info'][tooltip-conf*='left']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tooltip-info-bg-color;
// }

// [tooltip][tooltip-conf*='warning']:after {
//     background: $tooltip-warning-bg-color;
// }

// [tooltip][tooltip-conf*='warning']:before {
//     border-top-color: $tooltip-warning-bg-color;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='warning'][tooltip-conf*='right']:before {
//     border-top-color: transparent;
//     border-right-color: $tooltip-warning-bg-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='warning'][tooltip-conf*='bottom']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tooltip-warning-bg-color;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='warning'][tooltip-conf*='left']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tooltip-warning-bg-color;
// }

// [tooltip][tooltip-conf*='danger']:after {
//     background: $tooltip-danger-bg-color;
// }

// [tooltip][tooltip-conf*='danger']:before {
//     border-top-color: $tooltip-danger-bg-color;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='danger'][tooltip-conf*='right']:before {
//     border-top-color: transparent;
//     border-right-color: $tooltip-danger-bg-color;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='danger'][tooltip-conf*='bottom']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: $tooltip-danger-bg-color;
//     border-left-color: transparent;
// }

// [tooltip][tooltip-conf*='danger'][tooltip-conf*='left']:before {
//     border-top-color: transparent;
//     border-right-color: transparent;
//     border-bottom-color: transparent;
//     border-left-color: $tooltip-danger-bg-color;
// }

// [tooltip=''] {
//     &:after,
//     &:before {
//         display: none;
//     }
// }

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: $black;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 99999;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(10px);
  pointer-events: none;
  // @media (hover: none) {
  //   display: none;
  // }
  &.ng-tooltip-bottom-right {
    top: 100%;
    right: 0;
  }
  &.ng-tooltip-info {
    max-width: 250px;
  }
  &.lg {
    max-width: 300px !important;
  }
}
.ng-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: $black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent $black transparent;
}
.ng-tooltip-bottom-right:after {
  bottom: 100%;
  right: 20px;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent $black transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent $black;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent $black transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
  transform: translateY(0);
}

.hint {
  app-icon {
    width: 16px;
    height: 16px;
    fill: $blue;
  }
  div {
    min-width: 250px;
    right: 0;
    bottom: 100%;
    background-color: $black;
    color: $white;
    font-size: 12px;
    font-weight: 300;
    padding: 8px;
    border-radius: 8px;
    position: absolute;
    z-index: 500;
  }
}
