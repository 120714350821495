.goog2fa-auth {
  display: flex;
  justify-content: center;

  input {
    width: 45px;
    height: 58px;
    margin: 0 8px;
    border-radius: 8px;
    text-align: center;
    font-size: 20px;
    border: solid 2px $border;
    background-color: $input;
    color: $text;
    outline: 0;

    &:focus {
      border-color: darken($border, 20%);
      background-color: $white;
    }

    @media (max-width: 540px) {
      width: 36px;
      height: 50px;
      margin: 0 4px;
    }
  }

  &.error {
    input {
      border-color: $error;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
app-login-page {
  .film {
    position: fixed;
    right: 31%;
    top: 6%;
    z-index: 1;
  }
  .film1 {
    position: fixed;
    zoom: 1.6;
    bottom: -19%;
    left: 22%;
    transform: rotate(100deg);
    z-index: 1;
  }
  .circle {
    position: fixed;
    left: -18%;
    bottom: -25%;
    zoom: 1.6;
    z-index: 1;
  }
  .circle1 {
    position: fixed;
    zoom: 0.6;
    top: -5%;
    left: -2%;
    z-index: 1;
  }
  .circle2 {
    position: fixed;
    zoom: 1;
    bottom: 6%;
    right: 28%;
    z-index: 1;
  }
  .logo {
    position: fixed;
    left: 24%;
    top: 25%;
    z-index: 1;
  }
  .logo-white {
    width: 240px;
    position: fixed;
    right: 520px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}
app-login,app-register {
  @media (max-height: 520px) {
    .login-wrapper {
      justify-content: flex-start !important;
    }
  }
}