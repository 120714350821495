$white: #fff;
$black: #000000;
$blue: #3f72af;
$dark-blue: #071b33;
$text: #212121;
$gray: #c4c4c4;

$body: #f0f2f6;
$input: #f8f8f8;
$sidebar: #112d4e;
$border: #d6dbe0;
$border-dark: rgba($white, 0.2);
$error: #f56565;
$success: #48bb78;
$indigo: #667eea;
$pink: #ed64a6;
$orange: #ed8936;
$yellow: #edcb36;

$cq-red: #ea9e9e;
$cq-blue: #6db0d6;
$cq-green: #7dbc9a;
$cq-purple: #b29eea;
$cq-cyan: #bc7da3;
$cq-indigo: #7d8bbc;
$cq-olive: #bebb6d;

$tooltip-base-text-color: $white;
$tooltip-base-bg-color: $black;
$tooltip-base-border-color: $black;
//Types
$tooltip-invert-bg-color: $border;
$tooltip-success-bg-color: $success;
$tooltip-info-bg-color: $blue;
$tooltip-warning-bg-color: $orange;
$tooltip-danger-bg-color: $error;

:root[data-theme='light-theme'] {
  --body: #{$body};
}
$body-dark: #242424;
$body-light: #5f5959d9;
$sidebar-dark: #3b3b3b;
$primary-dark: #3d95ff;
:root[data-theme='dark-theme'] {
  --body: #{$body-dark};
}

body.dark {
  --body: #{$body-dark};
  --body-color: #{$white};
}

body.dcp-light-theme {
  --body: #{$body};
  --body-color: #{$black};
}

:root {
  --global-black-color: #{$text};
}
