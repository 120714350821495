.cdk-overlay-backdrop {
  background: rgba($body, 0.6);
  backdrop-filter: blur(4px);
}
.backdrop {
  overflow-y: auto !important;
}
.cdk-overlay-pane {
  @media (max-width: 540px) {
    max-width: calc(100% - 32px) !important;
    max-height: calc((var(--vh, 1vh) * 100) - 32px) !important;
  }
}
app-dialog-container {
  .dialog-header {
    font-size: 20px;
    padding: 16px;
    line-height: 18px;
    border-bottom: solid thin $border;
    font-weight: 700;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 2;
  }
  .dialog-content {
    padding: 16px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    app-checkbox {
      label {
        white-space: normal !important;
      }
    }
  }
  .dialog-footer {
    padding: 16px;
    border-top: solid thin $border;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    background-color: $white;
    z-index: 2;
  }
  // @media (max-width: 640px) {
  //   max-width: calc(100% - 32px) !important;
  //   height: calc((var(--vh, 1vh) * 100) - 32px) !important;
  //   max-height: calc((var(--vh, 1vh) * 100) - 32px) !important;
  // }
}
.dark {
  .backdrop {
    background: rgba($body-dark, 0.4) !important;
  }

  app-dialog-container {
    .dialog-content,
    .dialog-footer,
    .dialog-header {
      background-color: $sidebar-dark;
      color: $white;
    }
    .dialog-header {
      border-bottom: solid thin $border-dark;
    }

    .dialog-footer {
      border-top: solid thin $border-dark;
    }
  }
}
.kukai-modal button.confirm {
  height: 40px !important;
  border-radius: 8px !important;
  background-color: $blue;
}
