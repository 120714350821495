.toast {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: auto;
  max-width: 500px;
  min-width: 500px;
  max-height: auto;
  padding: 16px;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: 6px;
  animation: move 0.5s both;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    width: calc(100% - 32px);
    min-width: calc(100% - 32px);
    max-width: calc(100% - 32px);
  }
  &.toast-check {
    color: $white;
    background-color: $success;
    border-color: $success;
  }
  &.toast-info {
    color: $white;
    background-color: $blue;
    border-color: $blue;
  }
  &.toast-error {
    color: $white;
    background-color: $error;
    border-color: $error;
  }
  &.toast-warning {
    color: $white;
    background-color: $orange;
    border-color: $orange;
  }
}

@keyframes move {
  from {
    transform: translateX(-50%) translateY(100%);
  }
  to {
    transform: translateX(-50%) translateY(0);
  }
}
