.image-user-avatar {
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  background-color: darken($body, 20%);
  background-image: url("data:image/svg+xml,%3Csvg width='87' height='99' viewBox='0 0 87 99' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M71.5683 65.413L57.6809 61.9403L56.4991 57.2133C60.2148 53.534 62.7595 48.4807 63.5135 43.2969C65.5724 42.7169 67.1675 40.9514 67.4429 38.7367L68.3491 31.4866C68.5413 29.9568 68.0699 28.409 67.055 27.249C66.4604 26.5674 65.71 26.0527 64.88 25.7373L65.2135 18.9114L66.5693 17.5521C68.6103 15.3806 70.303 11.6578 66.7651 6.25651C64.0463 2.10618 59.4282 0 53.0337 0C50.5107 0 44.7035 0 39.2951 3.63224C23.8524 3.95493 21.75 11.1287 21.75 18.125C21.75 19.7527 22.145 23.4176 22.406 25.6288C21.4744 25.9224 20.6298 26.4661 19.97 27.2165C18.9369 28.3874 18.4584 29.9426 18.6506 31.4904L19.5568 38.7404C19.854 41.1039 21.6484 42.9635 23.9177 43.4058C24.668 48.3901 27.0823 53.2803 30.584 56.8981L29.3225 61.9476L15.4352 65.4203C6.34368 67.6859 0 75.8131 0 85.1873C0 86.1878 0.812055 86.9999 1.81255 86.9999C1.81255 86.9999 15.4318 99 43.5 99C71.5683 99 85.1875 86.9999 85.1875 86.9999C86.188 86.9999 87 86.1807 87 85.1802C86.9999 75.8131 80.6562 67.6859 71.5683 65.413Z' fill='%23E4E4E4'/%3E%3C/svg%3E%0A");
  background-size: 74% auto;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.dark {
  .image-user-avatar {
    background-color: darken($body-dark, 20%);
  }
}
