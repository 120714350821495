h1 {
  font-size: 40px !important;
}
h2 {
  font-size: 32px !important;
}
h3 {
  font-size: 24px !important;
}
h4 {
  font-size: 20px !important;
}
h5 {
  font-size: 18px !important;
}
